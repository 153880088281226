<template>
  <!-- Start Service Area  -->
  <v-row class="service-one-wrapper">
    <!-- Start Single Service  -->
    <v-col
      style="align-items: stretch; display: flex;"
      class=""
      lg="6"
      md="6"
      sm="6"
      cols="12"
      v-for="(service, i) in serviceContent"
      :key="i"
    >
      <div class="single-service service__style--4 mt-4">
        <router-link to="/services">
          <div class="service">
            <div class="icon" v-html="iconSvg(service.icon)"></div>
            <div class="content">
              <h3 class="heading-title">{{ service.title }}</h3>
              <p>
                {{ service.desc }}
              </p>
            </div>
          </div>
        </router-link>
      </div>
    </v-col>
    <!-- End Single Service  -->
  </v-row>
  <!-- Start Service Area  -->
</template>

<script>
  import feather from "feather-icons";
  export default {
    data() {
      return {
        serviceContent: [
          {
            icon: "code",
            title: "Entwicklung",
            desc: ` Wir programmieren Business Software und Applikationen. Dabei setzten wir auf modernste Technologien.`,
          },
          {
            icon: "layout",
            title: "Webapps",
            desc: `Webapps können ganz einfach sein. Erzähl uns von deiner Idee und wir machen sie zur Realität.`,
          },
          {
            icon: "cloud",
            title: "Cloud Services",
            desc: `Überlasse uns die Verantwortung! Wir hosten deine Homepage, Webapplikationen oder dein E-Mail.`,
          },
          {
            icon: "monitor",
            title: "Beratung",
            desc: `Stehst du irgendwo an? Gerne beraten wir dich bei Fragen rund um Hard- und Software.`,
          },
        ],
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
