<template>
  <!-- Start Slider Area  -->
  <div class="rn-slider-area">
    <!-- Start Single Slide  -->
    <div class="slide slider-fixed--height d-flex align-center bg_image slider-video-bg overlay-fix">
      <div class="video-overlay">
        <div class="video-background">
          <span>
            <video playsinline autoplay muted loop style="width: 100%; height: 100%; object-fit: cover;">
              <source :src='require("@/assets/videos/hero-1.webm")' type='video/mp4'>
            </video>
          </span>
        </div>
      </div>
      <v-container class="position-relative">
        <v-row>
          <v-col cols="12">
            <div class="inner pt-0">
              <h2 class="heading-title theme-gradient" style="vertical-align: center;">
                IT Lösungen.<br/>
                Individuell. Persönlich.
              </h2>
<!--              <h3 style="display:none; color: #e1be5f; font-weight: 300">Dies ist der kleine untertitel shüüüüsh</h3>-->
            </div>
          </v-col>
        </v-row>
        <!-- End .row -->
        <!-- Start Service Area  -->
<!--        <div class="service-wrapper service-white">-->
<!--          <ServiceOne/>-->
<!--        </div>-->
        <!-- End Service Area  -->
      </v-container>
    </div>
    <!-- End Single Slide  -->
  </div>
  <!-- End Slider Area  -->
</template>

<script>
import ServiceOne from "../../components/service/ServiceOne";

export default {
  components: {
    ServiceOne,
  }
};
</script>
<style lang="scss">

.video-background {
  position: absolute;

  &:before {
    content: '';
    position: absolute;
    //background: rgba(34, 23, 10, 0.85);
    background: rgba(38, 38, 38, 0.85);
    border-radius: 5px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.video-overlay {

}
</style>
